import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ArrayHelpers } from 'helpers/arrayHelpers';
import { CURRENTLY_SELECTED_VARIANT, RECENTLY_VIEWED } from 'helpers/constants/localStorage';
import { useAccount, useConfig, useProduct } from 'frontastic';
import CTProductListCard from '../../ct-product-list/ct-product-list-card';
import { observeElementsInViewport } from 'helpers/utils/viewportObserver';
import { gtmViewItemList } from 'helpers/utils/gtm-events/gtmEcommerce';
import { CurrencyHelpers } from 'helpers/currencyHelpers';

const RecentlyViewed = ({ currentProductId }) => {
  const [recentlyViewedProductsSkus, setRecentlyViewedProductsSkus] = useState([]);
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const [observerIntialised, setObeserverInitialised] = useState(false);
  const { getProduct } = useProduct();
  const router = useRouter();

  useEffect(() => {
    const isRecentlyViewPresent: any = JSON.parse(localStorage?.getItem(RECENTLY_VIEWED));
    setRecentlyViewedProductsSkus(isRecentlyViewPresent);
  }, [currentProductId]);

  const getProductData = async (skusArray) => {
    setRecentlyViewedProducts([]);
    skusArray = skusArray?.filter((obj) => obj != undefined);
    if (skusArray && skusArray.length > 0) {
      const productDataArray = await Promise.all(
        skusArray?.map(async (id) => {
          if (id !== localStorage?.getItem(CURRENTLY_SELECTED_VARIANT) && id) {
            const currentProductData = await getProduct({ sku: id });
            return currentProductData;
          }
          return null;
        }),
      );

      const arrayWithoutDuplicates = ArrayHelpers?.removeDuplicatesById(productDataArray);
      const filteredProductDataArray = arrayWithoutDuplicates.filter(
        (productData) =>
          productData !== null &&
          productData?.productId !== localStorage?.getItem(CURRENTLY_SELECTED_VARIANT)?.split(' ')[0],
      );
      setRecentlyViewedProducts([].concat(...filteredProductDataArray));
    }
  };

  useEffect(() => {
    const isRecentlyViewPresent: any = JSON.parse(localStorage?.getItem(RECENTLY_VIEWED));
    if (isRecentlyViewPresent?.length > 0) {
      getProductData(recentlyViewedProductsSkus);
    }
  }, [recentlyViewedProductsSkus]);

  useEffect(() => {
    if (recentlyViewedProducts && recentlyViewedProducts.length > 0 && !observerIntialised) {
      setObeserverInitialised(true);
      observeElementsInViewport({
        selector: '#recently-viewed-carousel',
        callback: (element) => {
          gtmViewItemList({
            item_list_id: router.asPath.split('/')?.[2]?.split('?')?.[0],
            item_list_name: 'Recently Viewed',
            filter_applied: false,
            items: recentlyViewedProducts.map((prod, i) => {
              const variant = { ...prod.variants?.[0] };

              const price = variant?.discountedPrice?.centAmount
                ? variant?.discountedPrice?.centAmount / 100
                : variant?.price?.centAmount
                ? variant?.price?.centAmount / 100
                : 0;

              const originalPrice = variant?.originalPrice?.centAmount
                ? variant?.originalPrice?.centAmount / 100
                : variant?.price?.centAmount
                ? variant?.price?.centAmount / 100
                : 0;

              const saleStatus = variant?.originalPrice != null || variant?.discountedPrice != null;

              const discount = Math.round((originalPrice - price) * 100) / 100;

              return {
                item_id: prod.variants?.[0]?.sku,
                item_name: prod.name,
                discount: discount,
                index: i,
                item_category: prod.categories?.[0]?.name,
                item_category2: prod.categories?.[1]?.name,
                item_variant: variant?.attributes?.['actualColor'],
                actual_size: variant.attributes?.['actualSize'],
                sale_status: saleStatus || false,
                stock_status: variant?.['isAvailable'],
                price: price,
                original_price: originalPrice,
                quantity: 1,
                item_image: variant?.images?.[0]?.url,
                item_url: prod?._url,
              };
            }),
          });
        },
      });
    }
  }, [recentlyViewedProducts]);

  return (
    <section>
      <ul
        className="grid w-full grid-cols-2 gap-x-5 gap-y-[30px] md:gap-x-[27px] lg:grid-cols-4"
        id="recently-viewed-carousel"
      >
        {recentlyViewedProducts?.map((product, index) => {
          if (index < 4) {
            return <CTProductListCard data={product} key={index} positions={index} source="recently-viewed" />;
          }
        })}
      </ul>
    </section>
  );
};

export default RecentlyViewed;
