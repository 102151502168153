import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useCurrentRefinements, useInfiniteHits } from 'react-instantsearch';
import PLPRichText from 'components/seo/plpRichText';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import ProductListCard from './product-list-card';
import { gtmViewItemList } from 'helpers/utils/gtm-events/gtmEcommerce';
import { gtmPLPInteraction } from 'helpers/utils/gtm-events/gtmInteraction';
import { observeElementsInViewport } from 'helpers/utils/viewportObserver';
import QuickViewModal from './quick-view-modal';

const processFilterAppliedData = (data) => {
  const result = {};
  data.forEach((item) => {
    if (!result[item.attribute]) {
      result[item.attribute] = [];
    }
    item.refinements.forEach((refinement) => {
      result[item.attribute].push(refinement.value);
    });
  });
  return result;
};

const Hits = (props) => {
  //   const sessionStorageCache = createInfiniteHitsSessionStorageCache();
  // const propsWithCache = {
  //     ...props,
  //     cache: sessionStorageCache
  // };
  const { hits, currentPageHits, results, isFirstPage, isLastPage, showMore, sendEvent } = useInfiniteHits(props);
  const { items } = useCurrentRefinements();
  const { data, currentCat, downrodsContent, isClearanceCategory, setCurrentPageNo, isSearchComponent } = props;
  const router = useRouter();
  const filterApplied = processFilterAppliedData(items);
  const observedElements = useRef(new Map());
  const debounceTimer = useRef(null); // Used to wait for all triggered observers and batch the items into a single datalayer event
  const triggeredElements = useRef([]);

  const changePageNo = () => {
    const currentPageNo = localStorage.getItem('pageNo');
    if (!currentPageNo) {
      localStorage.setItem('pageNo', '1');
    } else {
      localStorage.setItem('pageNo', (parseInt(currentPageNo) + 1).toString());
    }
  };

  const dataLayerEventTrigger = () => {
    if (triggeredElements.current.length > 0) {
      const breadCrumbs = data?.categoryData?.breadCrumb;
      gtmViewItemList({
        item_list_id: data?.categoryData?.categoryId,
        item_list_name: currentCat?.name,
        filter_applied: Object.keys(filterApplied).length > 0,
        selected_option: Object.keys(filterApplied)
          .map((key) => filterApplied[key])
          .join(','),
        filter_type: Object.keys(filterApplied).join(','),
        items: triggeredElements.current.map((prod, i) => {
          return {
            item_id: prod?.sku,
            item_name: prod?.name?.['en-US'],
            index: prod.index,
            discount: prod?.originalPrice && prod?.originalPrice != null ? (prod.originalPrice - prod.price) / 100 : 0,
            item_category: breadCrumbs?.[0]?.name?.toLowerCase(),
            item_category2: breadCrumbs?.length > 1 ? breadCrumbs[breadCrumbs.length - 1]?.name?.toLowerCase() : '',
            item_variant: prod?.actualColor?.[0] ?? '',
            sale_status: !!prod?.originalPrice && prod?.originalPrice != 0,
            stock_status: true,
            actual_size: prod?.actualSize?.[0],
            price: prod.price / 100,
            item_image: prod?.variant?.images?.[0]?.url,
            item_url: prod?._url,
          };
        }),
      });
    }
  };

  const handleRef = (element, hit, index) => {
    if (element) {
      if (!observedElements.current.has(hit.key)) {
        const observer = observeElementsInViewport({
          element: element,
          callback: () => {
            hit['index'] = index;
            triggeredElements.current.push(hit);

            if (debounceTimer.current) {
              clearTimeout(debounceTimer.current);
            }

            debounceTimer.current = setTimeout(() => {
              dataLayerEventTrigger();
              triggeredElements.current = [];
            }, 500);
          },
        });
        observedElements.current.set(hit.key, { observer, element });
      }
    } else if (observedElements.current.has(hit.key)) {
      const { observer, element: elem } = observedElements.current.get(hit.key);
      observer.unobserve(elem);
      observer.disconnect();
      observedElements.current.delete(hit.key);
    }
  };

  useEffect(() => {
    triggeredElements.current = [];
  }, [filterApplied]);

  return (
    <>
      <PLPRichText hits={hits} />
      {/* <div className="text-center">
        {!isFirstPage ? (
          <button
            className="mb-4 h-10 rounded bg-[#404040] px-5 text-sm font-bold leading-[17px] text-white"
            onClick={() => {
              // showPrevious();
            }}
            disabled={isLastPage}
          >
            {mapLocaleToMeaningfulFormat(router.locale).showPrevious}
          </button>
        ) : null}
      </div> */}
      {/* <CustomStateResults setCurrentFilters={setCurrentFilters} /> */}
      <ul className="grid grid-cols-2 gap-x-4 gap-y-[30px] sm:gap-x-5 md:gap-x-[27px] lg:grid-cols-3">
        {hits?.map((hit, index) => (
          <li key={index} id={`view-list-item-${hit.key}`} ref={(el) => handleRef(el, hit, index)}>
            <ProductListCard
              downrodsContent={downrodsContent}
              data={hit}
              key={index}
              positions={index}
              currentCat={currentCat}
              currentFilters={filterApplied}
              isClearanceCategory={isClearanceCategory}
              isSearchComponent={isSearchComponent}
            />
          </li>
        ))}
      </ul>
      <div className="text-center">
        {!isLastPage ? (
          <button
            className="mt-4 h-10 rounded bg-[#404040] px-5 text-sm font-bold leading-[17px] text-white"
            onClick={() => {
              setCurrentPageNo((pre) => pre + 1);
              gtmPLPInteraction({
                click_action: 'load_more',
              });
              changePageNo();
            }}
            disabled={isLastPage}
          >
            {mapLocaleToMeaningfulFormat(router.locale).loadMore}
          </button>
        ) : null}
      </div>
    </>
  );
};

const ProductListItem = ({
  currentCat,
  downrodsContent,
  isClearanceCategory,
  isSearchComponent,
  setCurrentPageNo,
  data,
}) => {
  return (
    <>
      <Hits
        data={data}
        downrodsContent={downrodsContent}
        currentCat={currentCat}
        isClearanceCategory={isClearanceCategory}
        isSearchComponent={isSearchComponent}
        setCurrentPageNo={setCurrentPageNo}
      />{' '}
    </>
  );
};

export default React.memo(ProductListItem);
